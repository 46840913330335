import React from 'react';
import cx from 'classnames';

import Container from 'components/Container/Container';
import Header from 'components/Header/Header';
import styles from './Home.module.scss';
import image from './shannon.jpg';
import SubHeading from 'components/SubHeading/SubHeading';
import Footer from 'components/Footer/Footer';
import { Link } from 'react-router-dom';

const Home: React.FC = () => (
    <>
        <Header />
        <SubHeading />
        <Container>
            <div className={cx(styles.home)}>
                <div className={cx(styles.wrap)}>
                    <img src={image} alt="Doctor Shaker" />
                </div>
                <p>
                    Shannon Shaker holds the position of Assistant Professor, Director of Bands at Grand Rapids Community College.
                    She leads the Wind Ensemble and Jazz Ensemble, and teaches applied clarinet, along with other music classes in
                    the department. Shannon served as Director of Bands and Music Education at Frostburg State University in Frostburg,
                    Maryland from 2018 - 2020. During her time at Frostburg State University, Shannon led the Wind Ensemble,
                    Marching Bobcats, oversaw the music education curriculum, supervised student teachers, and taught music education
                    and general education courses including: Conducting, Introduction to Music Education, Instrumental Methods,
                    Secondary General Music Methods, Elementary Music Methods, Gender and Sexuality in Music, and Music Appreciation.
                </p>
                <p>
                    Shannon holds a Doctorate of Musical Arts in Wind Conducting from Arizona State University where she was a conducting
                    student of Gary W. Hill, and served as a teaching assistant in the wind band program. Her research document,
                    &quot;Paucity of Female College Band Directors as Faculty and Conductors at National Conferences in the United States,
                    2017-2018,&quot; provided a much-needed update to gender issues currently facing wind bands, especially in higher education.
                </p>
                <p>
                    Dr. Shaker is the founder of the Women&apos;s Band Composition Contest which she created to encourage and support women
                    composers for wind band. Through the contest, women composers are connected with band directors from across the country,
                    and provided opportunities for performances and collaborations. More information about the contest, and how to get
                    involved can be found at <Link to="/competition">Competition Page</Link>.
                </p>
                <p>
                    Shannon is from Pinconning, Michigan, and earned her Bachelor of Music Education degree from Central Michigan University
                    in 2009. After earning her teaching certificate, Shannon taught for Eagle County Schools in Edwards, Colorado, where she
                    taught music to students of all ages. During her tenure there, she taught elementary music, high school band, music
                    technology, drumline, and guitar classes, while running First Notes, an El Sistema based youth orchestra. Shannon returned
                    to Michigan and taught elementary music and band for Ypsilanti Community Schools in Ypsilanti, MI before pursuing graduate
                    studies. She then earned her Master of Music degree in Wind Conducting from Central Michigan University, where she studied
                    with John E. Williamson, and was a teaching assistant for the wind band program.
                </p>
                <p>
                    As first-generation classical musician and college student, Shannon is a fierce advocate for music as a vehicle for social
                    change, and equitable and inclusive practices to ensure everybody has access to a high-quality music education. Her
                    teaching, service, and research are all driven by these values. Shannon strives for her and her students to be a part of
                    creating the future of classical music, and has been a part of several commissioning projects, and led a consortium for
                    Nicole Piunno&apos;s &quot;Where Words Cannot Go.&quot; Dr. Shaker conducted the premiere of the piece with the Frostburg State University
                    Wind Ensemble in March, 2020 with the composer in residence.
                </p>
                <p>
                    Dr. Shaker  is active in the professional music community. She is a member of the College Band Directors National
                    Association (CBDNA), College Music Society, National Association for Music Education, World Association for Symphonic
                    Bands and Ensembles (WASBE), and Women Band Director International (WBDI), where she has served as scholarship chair
                    since 2019. She is also on the Strategic Planning Committee for the Maryland Music Educators Association, and served
                    on a small-bands working group for the eastern division of CBDNA. Shannon is an active guest conductor, clinician, and presenter.
                </p>
                <p>
                    Shannon&apos;s hobbies outside of music include cooking, baking, dog walking, hiking, and travel with her husband and two rescue dogs.
                    Shannon and her husband are currently working on a website to connect living composers with musicians and encourage collaborations.
                </p>
            </div>
        </Container>
        <Footer />
    </>
);

export default Home;

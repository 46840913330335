import React from 'react';
import cx from 'classnames';

import styles from './Container.module.scss';

interface Props {
    className?: string;
    children?: React.ReactNode;
}

const Container: React.FC<Props> = ({ children, className }: Props) => (
    <div className={cx(styles.container, className)}>
        {children}
    </div>
);

export default Container;

import React, { useState } from 'react';
import cx from 'classnames';

import Container from 'components/Container/Container';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Grid from 'components/Grid/Grid';
import Video from 'components/Video/Video';

import styles from './Competition.module.scss';

interface Props {
    placeholder: any;
}

const Competition: React.FC<Props> = () => {
    const [currentTab, setCurrentTab] = useState(0);
    return (
        <>
            <Header />
            <Container>
                <div className={cx(styles.competition)}>
                    <h1 className={cx(styles.center, styles.pad)}>2021 Contest Information Coming Soon!</h1>
                    <h1 className={cx(styles.center, styles.pad)}>Winning Works</h1>
                    <Grid>
                        <Video url="https://www.youtube.com/embed/A2xnOGjIthU">
                            <div>
                                <div>2018 Winner</div>
                                <div>Dance the Joy Alive by Nicole Piunno</div>
                            </div>
                        </Video>
                        <Video url="https://www.youtube.com/embed/jR-TVXsXUP4">
                            <div>
                                <div>2019 Winner</div>
                                <div>Grade 3 - 4 Category</div>
                                <div>In Two Places by Haley Woodrow</div>
                            </div>
                        </Video>
                        <Video url="https://www.youtube.com/embed/Hef0MnM-Xpg">
                            <div>
                                <div>2019 Winner</div>
                                <div>Grade 1 - 2 Category</div>
                                <div>Into the Blue by Cait Nishimura</div>
                            </div>
                        </Video>
                    </Grid>

                    <div className={cx(styles.info)}>
                        {/* TABS */}
                        <div
                            className={cx(
                                styles.tab,
                                styles.pink,
                                { [styles.selected]: currentTab === 0 },
                            )}
                            onClick={() => setCurrentTab(0)}
                        >
                            2018 Information
                        </div>

                        <div
                            className={cx(
                                styles.tab,
                                styles.yellow,
                                { [styles.selected]: currentTab === 1 },
                            )}
                            onClick={() => setCurrentTab(1)}
                        >
                            2019 Information
                        </div>

                        {/* CONTENT */}
                        <div className={cx(
                            styles.blip,
                            styles.page,
                            styles.left,
                            styles.pink,
                            { [styles.open]: currentTab === 0 })}
                        >
                            <div className={cx(styles.content)}>
                                <div className={cx(styles.winning)}>
                                    <h2>2018 Winning Works</h2>
                                    <p>
                                        <h3>Winner</h3>
                                        <a href="https://www.nicolepiunno.com/dance-the-joy-alive">Dance the Joy Alive by Nicole Piunno</a>
                                    </p>
                                    <p>
                                        <h3>Honorable Mention</h3>
                                        <a href="https://molliebudiansky.com/flight-of-the-dragon/">Flight of the Dragon by Mollie Budiansky</a>
                                        <a href="http://c-alanpublications.com/into-the-unknown/">Into the Unknown by Karen Kachelmeier Robertson</a>
                                        <a href="https://www.spottedrocket.com/shop/p/byzantium">Byzantium by Nicole Chamberlain</a>
                                        <a href="https://www.youtube.com/watch?v=OyOZnNE-tVg">Through the Forest by Hanna-Mari Lehtonen</a>
                                    </p>
                                </div>

                                <div className={cx(styles.judges)}>
                                    <h2>2018 Contest Judges</h2>
                                    <p>
                                        The following musicians served as a panel of judges
                                        for the inaugural Women’s Band Composition Contest.
                                        A huge debt of gratitude goes out to every individual
                                        listed, for believing in this idea and providing
                                        opportunities for women composers.
                                    </p>
                                    <div>Andrew Anderson, University of Tulsa</div>
                                    <div>Judiva Baumanis</div>
                                    <div>Cassandra A. Bechard, University of Minnesota</div>
                                    <div>Trae Blanco, University of Southern Maine</div>
                                    <div>Fredrick M. Brown, Jr., Arizona State University</div>
                                    <div>Eric W. Bush, Pennsylvania State University</div>
                                    <div>Kelly Desjardins, University of North Texas</div>
                                    <div>Christopher Dobbins</div>
                                    <div>Jack A. Eaddy, Jr., University of North Texas</div>
                                    <div>Martin I. Gaines, University of Arizona</div>
                                    <div>Andrea Gonzalez</div>
                                    <div>Tim Heath</div>
                                    <div>Steven Kandow, University of Saint Francis</div>
                                    <div>Jeffrey A.  Malecki, University of San Diego</div>
                                    <div>Bryan Raya</div>
                                    <div>Onsby Rose</div>
                                    <div>Shayna Stahl, University of Washington</div>
                                    <div>Derek Stoughton, Southeastern Louisiana University</div>
                                    <div>Yim Chee Weng</div>
                                </div>

                                <div className={cx(styles.call)}>
                                    <h2>2018 Call for Scores</h2>

                                    <p>
                                        <h3>Call for Compositions</h3>
                                        We are excited to announce the call for scores for the 2018 Female Band
                                        Composition Competition. We are looking for band works that are approximately
                                        Grade Three level. The aim is to support works that appeal to a wide array of
                                        abilities and tastes. Please see below for all of the details.
                                    </p>

                                    <p>
                                        <h3>The Composition</h3>
                                        <ol>
                                            <li>
                                                Submissions must:
                                                <ul>
                                                    <li>be composed by someone who self-identifies as a female composer</li>
                                                    <li>be an original, unpublished composition for band.</li>
                                                    <li>be no more than 10 minutes in duration.</li>
                                                    <li>be approximately a difficulty level of Grade 3 (based on the 1-6 grade level system).</li>
                                                    <li>have been written within 5 years of the deadline.</li>
                                                </ul>
                                            </li>
                                            <li>Submissions will only be accepted electronically and must include a .pdf score, a recording (mp3, AAC, .wav, .aiff, etc.) and a completed entry form. While applicants are encouraged to send actual recordings, MIDI realizations will be accepted. PLEASE COMPRESS ALL AUDIO FILES SENT SO AS TO MINIMIZE FILE SIZE.</li>
                                            <li>Scores must not include the composer’s name, in order to facilitate blind judging.</li>
                                            <li>Composers may re-submit non-winning compositions from this or other competitions. However, once a work has been awarded a prize in any competition, it may not be submitted again.</li>
                                            <li>Concertos or solos/ensembles with band accompaniments are not acceptable.</li>

                                            <li>
                                                The maximum instrumentation is as follows:
                                                <ul>
                                                    <li>Flute (div. in a maximum of 2) + Piccolo*</li>
                                                    <li>Clarinet (div. in a maximum of 3) + bass clarinet*</li>
                                                    <li>Oboe* (2  parts)</li>
                                                    <li>Saxophone: Alto (div. in a maximum of 2), Tenor, Baritone*</li>
                                                    <li>Bassoon* (1 part)</li>
                                                    <li>Trumpet (div. in a maximum of 3)</li>
                                                    <li>Horn* (div. in a maximum of 2)</li>
                                                    <li>Trombone (div. in a maximum of 3)</li>
                                                    <li>Euphonium (1 part B.C. &amp; transposed for T.C.)</li>
                                                    <li>Tuba</li>
                                                    <li>Double Bass</li>
                                                    <li>Percussion in 4 parts (maximum 2 mallet instruments) + Timpani</li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </p>


                                    <div>* N.B.: Solos written for instruments marked (*) should be cross-cued in other instruments.</div>
                                    <p>
                                        <h3>The Competition Rules</h3>
                                        <ol>
                                            <li>Shannon Shaker (ASU)  will oversee the competition.</li>
                                            <li>Submissions will be accepted from individuals who self-identify as female.</li>
                                            <li>The deadline to submit is 5:00 pm MST on November 1st, 2017</li>
                                            <li>The composer of the winning composition will receive a travel stipend of up to  $1,000 to attend a rehearsal and the premiere of the piece.</li>
                                            <li>All copyright, mechanical rights, and royalty rights remain with the composer.</li>
                                            <li>The winner will be announced by email; notification will be sent to all applicants and panel membership, and results will be posted on the Kappa Kappa Psi - Beta Omicron website: http://theregoesbo.org.</li>
                                            <li>The decision of the jury will be final.</li>
                                            <li>Members of the jury will receive a score and set of parts of the winning composition.</li>
                                            <li>The piece will be premiered by Arizona State University&apos;s Concert Band, conducted by Shannon Shaker on Monday, April 23, 2018 at Gammage Auditorium in Tempe, AZ. It is the hope of the competition that the winning composer will be able to attend a rehearsal and this performance.</li>
                                        </ol>
                                    </p>

                                    <p>To ask questions about the competition, contact Shannon Shaker at: sshaker@asu.edu.</p>
                                    <p>
                                        <h3>The Sponsor</h3>
                                        <div>Kappa Kappa Psi, Beta Omicron is sponsoring this project. For information on how to be involved, including donating to the project, contact Aliyah Qualls at: adqualls@asu.edu</div>
                                        <div>For all additional questions, please contact Shannon Shaker at: sshaker@asu.edu</div>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div
                            className={cx(
                                styles.blip,
                                styles.page,
                                styles.right,
                                styles.yellow,
                                { [styles.open]: currentTab === 1 }
                            )}
                        >
                            <div className={cx(styles.content)}>
                                <div className={cx(styles.winning)}>
                                    <h2>2019 Winners</h2>
                                    <p>
                                        <h3>Grade 1 - 2 Winner</h3>
                                        <a href="https://caitnishimura.com/pages/into-the-blue">Into the Blue by Cait Nishimura</a>
                                    </p>
                                    <p>
                                        <h3>Grade 3 - 4 Winner</h3>
                                        <a href="https://haleywoodrow.com/music-in-two-places/">In Two Places by Haley Woodrow</a>
                                    </p>
                                    <p>
                                        <h3>Honorable Mention</h3>
                                        <a href="http://www.carriemagin.com/band">Letchworth Fanfare by Carrie Magin</a>
                                    </p>
                                </div>

                                <div className={cx(styles.judges)}>
                                    <h2>2019 Judges</h2>
                                    <p>
                                        <h3>Grade 1 - 2 Judges</h3>
                                        <div>Michelle Bell, Emory &amp; Henry College</div>
                                        <div>Julie Bounds, Sheldon High School</div>
                                        <div>Amanda Burdette, Marshall Public Schools</div>
                                        <div>Andrea Gonzalez, Anderson High School</div>
                                        <div>Jeffrey Malecki, University of San Diego</div>
                                        <div>Dana Mandell, University of Notre Dame</div>
                                        <div>Andrew Pease, Hartwick College</div>
                                        <div>Derek Stoughton, Southeastern Louisiana University</div>
                                        <div>Katie Strong, Somerset Area School District</div>
                                        <div>Erick Von Sas, Old Mill High School</div>
                                    </p>
                                    <p>
                                        <h3>Grade 3 - 4 Judges</h3>
                                        <div>Cassandra Bechard, University of Dubuque</div>
                                        <div>Michelle Bell, Emory &amp; Henry College</div>
                                        <div>Julie Bounds, Sheldon High School</div>
                                        <div>Andrea Gonzalez, Anderson High School</div>
                                        <div>Brian Hughes, Quad City Wind Ensemble</div>
                                        <div>Janet Song Kim, UCLA</div>
                                        <div>Jeffrey Malecki, University of San Diego</div>
                                        <div>Dana Mandell, University of Notre Dame</div>
                                        <div>Andrew Pease, Hartwick College</div>
                                        <div>Onsby C. Rose, Dordt University</div>
                                        <div>Derek Stoughton, Southeastern Louisiana University</div>
                                        <div>Yim Chee Weng, Graceland University</div>

                                    </p>
                                </div>

                                <div className={cx(styles.call)}>
                                    <h2>2018 Call for Scores</h2>
                                    <p>
                                        <h3>Call for Compositions</h3>

                                        We are excited to announce the call for scores for the Second Annual
                                        Women’s Band Composition Contest. We are looking for works in two categories:
                                        approximately grade 1-2, and approximately grade 3-4. These categories will
                                        be judged separately, and a winner will be named in each category. The aim
                                        is to support works that appeal to a wide array of abilities and tastes.
                                        Please see below for all of the details.
                                    </p>

                                    <p>
                                        <h3>The Composition (Grade 1 - 2)</h3>
                                        <ol>
                                            <li>
                                                Submissions must
                                                <ul>
                                                    <li>be composed by someone who self-identifies as a woman composer.</li>
                                                    <li>be an original composition for band.</li>
                                                    <li>be no more than 6 minutes in duration.</li>
                                                    <li>be approximately a difficulty level of Grade 1 – 2 (based on the 1-6 grade level system).</li>
                                                    <li>have been written within 5 years of the deadline.</li>
                                                </ul>
                                            </li>
                                            <li>Submissions will only be accepted electronically and must include a .pdf score, a recording (mp3, AAC, .wav, .aiff, etc.) and a completed entry form. While applicants are encouraged to send actual recordings, MIDI realizations will be accepted. PLEASE COMPRESS ALL AUDIO FILES SENT AS TO MINIMIZE FILE SIZE.</li>
                                            <li>Scores must not include the composer’s name, in order to facilitate blind judging.</li>
                                            <li>Composers may re-submit non-winning compositions from this or other competitions. However, once a work has been awarded a prize in any competition, it may not be submitted again.</li>
                                            <li>Concertos or solos/ensembles with band accompaniments are not acceptable.</li>
                                            <li>
                                                The maximum instrumentation is as follows:
                                                <ul>
                                                    <li>Flute (div. in a maximum of 2)</li>
                                                    <li>Clarinet (div. in a maximum of 3) + bass clarinet*</li>
                                                    <li>Oboe* (1 part)</li>
                                                    <li>Saxophone: Alto (div. in a maximum of 2), Tenor, Baritone*</li>
                                                    <li>Bassoon* (1 part)</li>
                                                    <li>Trumpet (div. in a maximum of 2)</li>
                                                    <li>Horn* (div. in a maximum of 2)</li>
                                                    <li>Trombone (div. in a maximum of 2)</li>
                                                    <li>Euphonium (1 part B.C. &amp; transposed for T.C.)</li>
                                                    <li>Tuba</li>
                                                    <li>Percussion in 4 parts (maximum 2 mallet instruments) + Timpani</li>
                                                </ul>
                                            </li>
                                        </ol>
                                        <div>* N.B.: Solos written for instruments marked (*) should be cross-cued in other instruments. Other cross-cuing is generally encouraged.</div>
                                    </p>
                                    <p>
                                        <h3>The Composition (Grade 3 - 4)</h3>
                                        <ol>
                                            <li>
                                                Submissions must
                                                <ul>
                                                    <li>be composed by someone who self-identifies as a woman composer.</li>
                                                    <li>be an original composition for band.</li>
                                                    <li>be no more than 10 minutes in duration.</li>
                                                    <li>be approximately a difficulty level of Grade 3 - 4 (based on the 1-6 grade level system).</li>
                                                    <li>have been written within 5 years of the deadline.</li>
                                                </ul>
                                            </li>
                                            <li>Submissions will only be accepted electronically and must include a .pdf score, a recording (mp3, AAC, .wav, .aiff, etc.) and a completed entry form. While applicants are encouraged to send actual recordings, MIDI realizations will be accepted. PLEASE COMPRESS ALL AUDIO FILES SENT AS TO MINIMIZE FILE SIZE.</li>
                                            <li>Scores must not include the composer’s name, in order to facilitate blind judging.</li>
                                            <li>Composers may re-submit non-winning compositions from this or other competitions. However, once a work has been awarded a prize in any competition, it may not be submitted again.</li>
                                            <li>Concertos or solos/ensembles with band accompaniments are not acceptable.</li>
                                            <li>
                                                The maximum instrumentation is as follows:
                                                <ul>
                                                    <li>Flute (div. in a maximum of 2) + piccolo*</li>
                                                    <li>Clarinet (div. in a maximum of 3) + bass clarinet*</li>
                                                    <li>Oboe* (div. In a maximum of 2)</li>
                                                    <li>Saxophone: Soprano*, Alto (div. in a maximum of 2), Tenor, Baritone*</li>
                                                    <li>Bassoon* (div. In a maximum of 2)</li>
                                                    <li>Trumpet (div. in a maximum of 3)</li>
                                                    <li>Horn* (div. in a maximum of 4)</li>
                                                    <li>Trombone (div. in a maximum of 2)</li>
                                                    <li>Euphonium (1-part B.C. &amp; transposed for T.C.)</li>
                                                    <li>Tuba</li>
                                                    <li>Double Bass*</li>
                                                    <li>Percussion in 4 parts (maximum 2 mallet instruments) + Timpani</li>
                                                </ul>
                                            </li>
                                        </ol>

                                        <div>* N.B.: Solos written for instruments marked (*) should be cross-cued in other instruments. Other cross-cueing is generally encouraged.</div>
                                    </p>
                                    <p>
                                        <h3>The Competition</h3>
                                        <ol>
                                            <li>Shannon Shaker will oversee the competition.</li>
                                            <li>Submissions will be accepted from individuals who identify as a woman.</li>
                                            <li>The deadline to submit is 5:00 p.m. March 1, 2019.</li>
                                            <li>
                                                The composer of each winning composition will receive a travel stipend to attend a rehearsal and the performance of the piece along with a $500 prize.
                                                <ol className={cx(styles.alpha)}>
                                                    <li>The grade 1 – 2 winning performance will take place at Frostburg State University) Shannon Shaker, conductor) in Frostburg, MD in the fall of 2019.</li>
                                                    <li>The grade 3 – 4 winning performance will take place at the University of San Diego, (Jeffrey Malecki, conductor) San Diego, CA in the 2019 – 2020 school year.</li>
                                                </ol>
                                            </li>
                                            <li>All copyright, mechanical rights, and royalty rights remain with the composer.</li>
                                            <li>The winner will be announced by email; notification will be sent to all applicants and panel membership, and results will be posted on Shannon’s website: www.shannonshaker.com</li>
                                            <li>The decision of the jury will be final.</li>
                                            <li>
                                                Members of the jury will receive a .pdf score and set of parts of the winning composition for performance.
                                                <ol className={cx(styles.alpha)}>
                                                    <li>All applicants whose submission has been published must ensure, should their piece be awarded, the score and parts set will be made available to the performing ensembles at no cost.</li>
                                                </ol>
                                            </li>
                                            <li>
                                                The score of the winning work will recognize the members of the jury/consortium in future publication/distribution.
                                                <ol>
                                                    <li>All applicants whose submission has been published must ensure, should their piece be awarded, they are able to recognize the contest and judges on new scores for the work.</li>
                                                </ol>
                                            </li>
                                            <li>All submissions that fail to meet any of the rules and requirements will not be considered for winning.</li>
                                        </ol>
                                    </p>
                                    <p>
                                        All questions regarding the competition should be directed to: Shannon Shaker at: shake1sm@gmail.com
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Footer />
        </>
    );
};

export default Competition;

import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import './styles/main.scss';
import Media from 'scenes/Media/Media';
import Home from 'scenes/Home/Home';
import Competition from 'scenes/Competition/Competition';
import ZoomSeries from 'scenes/ZoomSeries/ZoomSeries';

const App = (): JSX.Element => (
    <Router>
        <Switch>
            <Route path="/competition" component={Competition} />
            <Route path="/media" component={Media} />
            <Route path="/zoom-series" component={ZoomSeries} />
            <Route path="/" component={Home} />
        </Switch>
    </Router>
);

export default App;

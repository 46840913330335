import React from 'react';
import cx from 'classnames';

import styles from './Footer.module.scss';

const Footer: React.FC<unknown> = () => (
    <footer className={cx(styles.footer)}>
        <div className={cx(styles.mission)}>
            <div className={cx(styles.sectionHeader)}>Contact Me</div>
            <div>Email: shake1sm@gmail.com</div>
        </div>
    </footer>
);

export default Footer;

import React from 'react';
import cx from 'classnames';

import styles from './Grid.module.scss';

interface Props {
    children?: React.ReactNode;
}

const Grid: React.FC<Props> = ({ children }: Props) => (
    <div className={cx(styles.grid)}>
        {children}
    </div>
);

export default Grid;

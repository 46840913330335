import React from 'react';
import cx from 'classnames';

import styles from './ZoomEvent.module.scss';

interface Props {
    imageSource: string;
    imageAlt: string;
    eventDate: string;
    guestSpeaker: string;
    registerLink: string;
}

const ZoomEvent: React.FC<Props> = ({
    imageSource,
    imageAlt,
    eventDate,
    guestSpeaker,
    registerLink,
}: Props) => (
    <div className={styles.zoomEvent}>
        <img
            className={styles.image}
            src={imageSource}
            alt={imageAlt}
        />

        <div className={styles.info}>
            <div>{eventDate}</div>
            <div>with {guestSpeaker}</div>
        </div>

        <a
            className={styles.register}
            href={registerLink}>
            Register
        </a>
    </div>
);

export default ZoomEvent;

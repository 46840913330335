import React from 'react';
import cx from 'classnames';

import styles from './Media.module.scss';
import Container from 'components/Container/Container';
import Header from 'components/Header/Header';
import Video from 'components/Video/Video';
import Grid from 'components/Grid/Grid';
import holidyConcert from './holiday_concert.jpg';
import Footer from 'components/Footer/Footer';

interface Props {
    placeholder: any;
}

const Media: React.FC<Props> = () => (
    <>
        <Header />
        <Container>
            <div className={cx(styles.media)}>
                <Grid>
                    <Video url="https://www.youtube.com/embed/j7lvCskKVWI">
                        <p>
                            World premiere performance of “Where Words Cannot Go”
                            by Nicole Piunno, performed by the Frostburg State
                            University Wind Ensemble
                        </p>
                    </Video>
                    <Video url="https://www.youtube.com/embed/oX3agaSCsf4">
                        <p>
                            “Chasing Sunlight” by Cait Nishimura  at Western High School in Baltimore,
                            Maryland. Performed by the Frostburg State University Wind Ensemble and the
                            Western High School Symphony Band during a side-by-side concert.
                        </p>
                    </Video>
                    <Video url="https://www.youtube.com/embed/VeQTA_JbxaQ">
                        <p>
                            “Machiavelli&apos;s Conscious” by Michael Markowski, performed
                            by musicians from the Arizona State University Wind Orchestra.
                        </p>
                    </Video>
                    <Video url="https://www.youtube.com/embed/QVa5lTQLDu4">
                        <p>
                            Little Threepenny Music by Kurt Wiell. Performed by
                            musicians from the Arizona State University Wind Orchestra.
                        </p>
                    </Video>
                    <Video url="https://www.youtube.com/embed/vmPyAk2v2xA">
                        <p>
                            O Magnum Mysterium by Morten Lauridsen, arranged by H. Robert Reynolds.
                            Performed by the 132nd Wisconsin Army Band at The Band Conductor&apos;s Art
                            Workshop at the University of Wisconsin, Platteville
                        </p>
                    </Video>
                    <Video url="https://www.youtube.com/embed/x2ufsk3K9lc">
                        <p>
                            Consortium premiere of “Lamentations for Trumpet and Wind Ensemble” by Onsby Rose.
                            Performed by the Frostburg State University Wind Ensemble, featuring Deborah Caldwell on trumpet.
                        </p>
                    </Video>
                </Grid>

                <div className={cx(styles.holidayConcert)}>
                    <img src={holidyConcert} alt="A photo of Dr. Shaker conducting the holiday collage concert at Frostburg State University" />
                    <caption>FSU Holiday Concert, 2019</caption>
                </div>
            </div>
        </Container>
        <Footer />
    </>
);

export default Media;

import React, { useState } from 'react';
import cx from 'classnames';
import { Link, useRouteMatch } from 'react-router-dom';

import caret from './expand-button.svg';
import styles from './Header.module.scss';

type Entry = [string, string];
const pathMap: Record<string, string> = {
    '/': 'Home',
    '/media': 'Media',
    '/competition': 'Women\'s Band Composition Contest',
    '/zoom-series': 'Zoom Series'
};

const Header = (): JSX.Element => {
    const [open, setOpen] = useState(false);
    const [contactOpen, setContactOpen] = useState(false);
    const match = useRouteMatch();

    const getLink = ([key, value]: Entry, i: number) => (
        <Link key={key} to={key}>
            <div className={cx(styles.link, { [styles.contrast]: i % 2 === 0 })}>
                {value}
            </div>
        </Link>
    );

    const navItems = () => Object
        .entries(pathMap)
        .map(getLink);

    return (
        <header>
            <div className={cx(styles.header)}>
                <div className={styles.logo}>Shannon Shaker, DMA</div>
                <div className={styles.toggle} onClick={() => setOpen(!open)}>
                    {pathMap[match.path]}
                    <img className={cx(styles.caret, { [styles.open]: open })} src={caret} alt="" />
                </div>
            </div>
            <nav className={cx(styles.expander, { [styles.open]: open })}>
                {navItems()}
                <div>
                    <div onClick={_ => setContactOpen(!contactOpen)} className={cx(styles.link, styles.contact)}>
                        <div>Contact<span className={cx(styles.inlineContact)}>: shake1sm@gmail.com</span></div>
                        <div className={cx(styles.info, { [styles.open]: contactOpen })}>shake1sm@gmail.com</div>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;

import React from 'react';
import cx from 'classnames';

import Container from 'components/Container/Container';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import styles from './ZoomSeries.module.scss';

import imgLetsTalk from './lets_talk.png';
import ZoomEvent from './components/ZoomEvent/ZoomEvent';

interface Props {
    placeholder: any;
}

const ZoomSeries: React.FC<Props> = () => (
    <div className={styles.page}>
        <Header />
        <Container className={styles.flexGrow}>
            <div className={cx(styles.zoomSeries)}
            >
                <div className={styles.background}>
                    <h1 className={styles.heading}>Let&apos;s Talk About It:</h1>
                    <h2 className={styles.heading}>Gender Bias in Music Education</h2>
                    <div className={styles.content}>
                        <ZoomEvent
                            imageSource={imgLetsTalk}
                            imageAlt=""
                            eventDate="June 12, 2021 7pm EDT"
                            guestSpeaker="Dr. Pam Klena"
                            registerLink="https://forms.gle/ZFz8bcbTgRstpiGb8"
                        />
                    </div>
                </div>
            </div>
        </Container>
        <Footer />
    </div>
);

export default ZoomSeries;

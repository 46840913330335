import React from 'react';
import cx from 'classnames';

import styles from './Video.module.scss';

interface Props {
    url: string;
    children?: React.ReactNode;
}

const Video: React.FC<Props> = ({ url, children }: Props) => (
    <div className={cx(styles.video)}>
        <div className={cx(styles.display)}>
            <iframe
                className={cx(styles.iframe)}
                src={url}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
            ></iframe>
        </div>
        <div className={cx(styles.description)}>
            {children}
        </div>
    </div>
);

export default Video;

import React from 'react';
import cx from 'classnames';

import styles from './SubHeading.module.scss';

const SubHeading: React.FC<unknown> = () => (
    <div className={cx(styles.subHeading)}>
        <span>
            Conductor
            <span className={cx(styles.separator)}>|</span>
            Educator
            <span className={cx(styles.separator)}>|</span>
            Community Leader
        </span>
    </div>
);

export default SubHeading;
